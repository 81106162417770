
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElForm,
  ElFormItem,
  ElInput,
} from "element-plus";

// Services
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElForm,
    ElFormItem,
    ElInput,
  },
})
export default class AppAdministratorMasterDataInvoiceTypesListFormFilterIndexVue extends Vue {
  isLoading = false;

  formData = {
    searchQuery: ""
  };

  get translation(): any {
    return getTranslation([
      "filter",
      "search",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  async submitForm(): Promise<void> {
    this.$emit('getInvoiceTypes', this.formData.searchQuery.length ? this.formData.searchQuery : "*");
  }
}
